/* eslint-disable react-hooks/exhaustive-deps */
import { useApi } from '@hooks/api'
import { PortifolioPageContext } from './context'
import { TaskApi } from '@services/api/task_api'
import { PortifolioPageControllerProps } from './types'
import { useCallback, useEffect, useMemo, useState } from 'react'

export const PortifolioPageController = (
  props: PortifolioPageControllerProps
): JSX.Element => {
  const api = useApi()
  const [loading, setLoading] = useState<boolean>(true)

  const handleLoadPortifolio = useCallback(() => {
    setLoading(true)

    api
      .instanceOf<TaskApi>(TaskApi)
      .list()
      .then((Portifolio: any) => {
        console.log(Portifolio)
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  useEffect(() => {
    handleLoadPortifolio()
  }, [])

  const state = useMemo(
    () => ({
      loading,
    }),
    [loading]
  )

  return (
    <PortifolioPageContext.Provider value={state}>
      {props.children}
    </PortifolioPageContext.Provider>
  )
}
