import Backlog from '@components/backlog'
import { TasksPageContext } from './context'
import { Box, Button, LinearProgress, Typography } from '@mui/material'
import { useContextSelector } from 'use-context-selector'
import { Card } from '@mui/material'

export const TasksPageView = (): JSX.Element => {
  const tasks = useContextSelector(TasksPageContext, (s) => s.tasks)
  const loading = useContextSelector(TasksPageContext, (s) => s.loading)

  if (!!loading) {
    return <LinearProgress />
  }

  return (
    <Card sx={{ display: 'flex', flexDirection: 'column', gap: 5, padding: 4 }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Typography>
          As Sprints possuem duração de <b>1 semana</b> e podem ser montadas
          toda <b>quinta-feira</b>
        </Typography>
        <Button variant="contained">Salvar Sprint</Button>
      </Box>
      <Backlog tasks={tasks} />
    </Card>
  )
}
