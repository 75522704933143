import { TasksPageView } from './view'
import { TasksPageProps } from './types'
import { TasksPageController } from './controller'

export const TasksPage = (props: TasksPageProps): JSX.Element => {
  return (
    <TasksPageController {...props}>
      <TasksPageView />
    </TasksPageController>
  )
}
