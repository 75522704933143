import { Menu } from '../menu'
import { WrapperProps } from './types'
import { Box, CssBaseline } from '@mui/material'

export const WrapperComponent = (props: WrapperProps): JSX.Element => {
  return (
    <Box sx={{ height: '100vh', display: 'flex', padding: 2, gap: 4, backgroundColor: '#f0f2f5' }}>
      <CssBaseline />
      <Menu />
      <Box sx={{ minHeight: '100%', width: '100%', overflow: 'scroll', paddingBottom: 10 }}>
        {props.children}
      </Box>
    </Box>
  )
}
