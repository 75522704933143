/* eslint-disable react-hooks/exhaustive-deps */
import { useCookies } from '@hooks/cookies'
import { useLocation } from 'react-router-dom'
import { UserEntity } from '@entities/UserEntity'
import React, { useState, useMemo, createContext, useContext } from 'react'

const Context = createContext(
  {} as {
    user: UserEntity | undefined
    account_host: string
    setUser: (user: UserEntity) => void
    account: () => void
    disconnect: () => void
    setContext: () => void
  }
)

const AuthProvider = (props: {
  apiBaseURL?: string
  children: JSX.Element
}) => {
  const cookies = useCookies()
  const location = useLocation()

  const searchParams = new URLSearchParams(location.search)
  const authorization = searchParams.get('Authorization')
  const sessionCookie = cookies.getCookie('Authorization') as unknown as string

  if (authorization && !sessionCookie) {
    cookies.setCookies({ Authorization: authorization })
  }

  const account_host = useMemo(
    () => ['account', ...window.location.host.split('.').slice(1)].join('.'),
    []
  )

  const [user, setUser] = useState<UserEntity | undefined>(
    JSON.parse((cookies.getCookie('user') || '{}') as unknown as string)
  )

  const account = React.useCallback(() => {
    window.location.replace(`https://${account_host}`)
  }, [])

  const disconnect = React.useCallback(() => {
    cookies.removeCookie('user')
    cookies.removeCookie('Authorization')

    window.location.replace(
      `https://${account_host}/login?callback=${window.location.href}`
    )
  }, [])

  const setContext = React.useCallback(() => {
    window.location.replace(
      `https://${account_host}/context?callback=${window.location.href}`
    )
  }, [])

  const authProviderValues = useMemo(
    () => ({
      user,
      setUser,
      account,
      disconnect,
      setContext,
      account_host,
    }),
    [user, setUser, account, disconnect, setContext, account_host]
  )

  return (
    <Context.Provider value={authProviderValues}>
      {props.children}
    </Context.Provider>
  )
}

const useAuth = () => {
  const context = useContext(Context)
  return context
}

export { AuthProvider, useAuth }
