import React from 'react'
import { Task } from '@type/task'
import { useModal } from '@hooks/modal'
import { Draggable } from 'react-beautiful-dnd'
import { Card, Typography, Box, Chip } from '@mui/material'

interface ItemProps {
  element: Task
  index: number
}

const Item: React.FC<ItemProps> = ({ element, index }) => {
  const { openModal } = useModal()

  const handleOpen = () => {
    openModal(
      <Box sx={{ padding: 4 }}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {element.roles.concat(', ')}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {element.description}
        </Typography>
      </Box>
    )
  }

  return (
    <Draggable draggableId={`${element.id}-${index}`} index={index}>
      {(provided) => (
        <Card
          onClick={handleOpen}
          sx={{ display: 'flex', gap: 2, padding: 2 }}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <Chip label={element.points} variant="outlined" />
          <Chip label={`R$${element.value}`} />
          <Typography>{element.description}</Typography>
        </Card>
      )}
    </Draggable>
  )
}

export default Item
