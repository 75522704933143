import { PortifolioPageView } from './view'
import { PortifolioPageProps } from './types'
import { PortifolioPageController } from './controller'

export const PortifolioPage = (props: PortifolioPageProps): JSX.Element => {
  return (
    <PortifolioPageController {...props}>
      <PortifolioPageView />
    </PortifolioPageController>
  )
}
