/* eslint-disable react-hooks/exhaustive-deps */
import { useApi } from '@hooks/api'
import { SprintPageContext } from './context'
import { TaskApi } from '@services/api/task_api'
import { SprintPageControllerProps } from './types'
import { useCallback, useEffect, useMemo, useState } from 'react'

export const SprintPageController = (
  props: SprintPageControllerProps
): JSX.Element => {
  const api = useApi()
  const [loading, setLoading] = useState<boolean>(true)

  const handleLoadSprint = useCallback(() => {
    setLoading(true)

    api
      .instanceOf<TaskApi>(TaskApi)
      .list()
      .then((Sprint: any) => {
        console.log(Sprint)
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  useEffect(() => {
    handleLoadSprint()
  }, [])

  const state = useMemo(
    () => ({
      loading,
    }),
    [loading]
  )

  return (
    <SprintPageContext.Provider value={state}>
      {props.children}
    </SprintPageContext.Provider>
  )
}
