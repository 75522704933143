import { NewProjectPageView } from './view'
import { NewProjectPageProps } from './types'
import { NewProjectPageController } from './controller'

export const NewProjectPage = (props: NewProjectPageProps): JSX.Element => {
  return (
    <NewProjectPageController {...props}>
      <NewProjectPageView />
    </NewProjectPageController>
  )
}
