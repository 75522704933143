import { SprintPageView } from './view'
import { SprintPageProps } from './types'
import { SprintPageController } from './controller'

export const SprintPage = (props: SprintPageProps): JSX.Element => {
  return (
    <SprintPageController {...props}>
      <SprintPageView />
    </SprintPageController>
  )
}
