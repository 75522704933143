import Column from './column'
import { Task } from '@type/task'
import { useEffect, useState } from 'react'
import { DragDropContext, DropResult } from 'react-beautiful-dnd'

export interface KanbanProps {
  tasks: Task[]
}

const initialColumns = {
  todo: {
    id: 'todo',
    name: 'Tarefas',
    list: [] as Task[],
  },
  doing: {
    id: 'doing',
    name: 'Em andamento',
    list: [] as Task[],
  },
  verify: {
    id: 'verify',
    name: 'Verificar',
    list: [] as Task[],
  },
  done: {
    id: 'done',
    name: 'Aprovadas',
    list: [] as Task[],
  },
}

export default function Kanban({ tasks }: KanbanProps) {
  const [columns, setColumns] = useState<Record<string, any>>(initialColumns)

  useEffect(() => {
    setColumns({
      todo: {
        id: 'todo',
        name: 'Tarefas',
        list: tasks.filter(task => task.status === 'todo'),
      },
      doing: {
        id: 'doing',
        name: 'Em andamento',
        list: tasks.filter(task => task.status === 'doing'),
      },
      verify: {
        id: 'verify',
        name: 'Verificar',
        list: tasks.filter(task => task.status === 'verify'),
      },
      done: {
        id: 'done',
        name: 'Aprovadas',
        list: tasks.filter(task => task.status === 'done'),
      }
    })
  }, [tasks])

  const onDragEnd = ({ source, destination }: DropResult) => {
    // Make sure we have a valid destination
    if (destination === undefined || destination === null) return null

    // If the source and destination columns are the same
    // AND if the index is the same, the item isn't moving
    if (
      source.droppableId === destination.droppableId &&
      destination.index === source.index
    )
      return null

    // Set start and end variables
    const start = columns[source.droppableId]
    const end = columns[destination.droppableId]

    // If start is the same as end, we're in the same column
    // If start is the same as end, we're in the same column
    if (start === end) {
      /* ... */
    } else {
      // If start is different from end, we need to update multiple columns
      // Filter the start list like before
      const newStartList = start.list.filter(
        (_: any, idx: number) => idx !== source.index
      )

      // Create a new start column
      const newStartCol = {
        id: start.id,
        name: start.name,
        list: newStartList,
      }

      // Make a new end list array
      const newEndList = end.list

      // Insert the item into the end list
      newEndList.splice(destination.index, 0, start.list[source.index])

      // Create a new end column
      const newEndCol = {
        id: end.id,
        name: end.name,
        list: newEndList,
      }

      // Update the state
      setColumns((state) => ({
        ...state,
        [newStartCol.id]: newStartCol,
        [newEndCol.id]: newEndCol,
      }))
      return null
    }

    return null
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr 1fr',
          margin: '24px auto',
          gap: '8px',
        }}
      >
        {Object.values(columns).map((col) => (
          <Column col={col} key={col.id} />
        ))}
      </div>
    </DragDropContext>
  )
}
