import { Bot } from './containers/bot'
import { Form } from './containers/form'
import { Fragment, useState } from 'react'
import { Submit } from './containers/submit'
import { NewProjectPageContext } from './context'
import { useContextSelector } from 'use-context-selector'

import {
  Box,
  Button,
  Card,
  CircularProgress,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material'

export const NewProjectPageView = (): JSX.Element => {
  const [activeStep, setActiveStep] = useState(0)

  const error = useContextSelector(NewProjectPageContext, (s) => s.error)

  const loading = useContextSelector(NewProjectPageContext, (s) => s.loading)

  const blocked = useContextSelector(NewProjectPageContext, (s) => s.blocked)

  const steps = useContextSelector(NewProjectPageContext, (s) => s.steps)

  const isSubmit = activeStep === steps.length - 1

  const handleSubmitProject = useContextSelector(
    NewProjectPageContext,
    (s) => s.handleSubmitProject
  )

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const submit = async () => {
    if (isSubmit) {
      await handleSubmitProject()
    }
    handleNext()
  }

  return (
    <Card sx={{ display: 'flex', flexDirection: 'column', padding: 4, pt: 6, width: '100%', minHeight: '100%', borderRadius: 4, justifyContent: 'space-between' }}>

      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {}
          return (
            <Step key={label + index} {...stepProps}>
              <StepLabel>{label}</StepLabel>
            </Step>
          )
        })}
      </Stepper>

      <Box sx={{ padding: 3 }}>
        {activeStep === 0 && <Bot />}
        {activeStep === 1 && <Form />}
        {activeStep === 2 && <Submit />}
      </Box>

      <Box>
        {loading && (
          <CircularProgress />
        )}

        {activeStep === steps.length && (
          <Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
              {error || 'Projeto enviado para o time Think Forward!'}
            </Typography>
          </Fragment>
        )}

        <Fragment>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Voltar
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            {
              activeStep < steps.length && <Button onClick={submit} disabled={blocked}>
                {isSubmit ? 'Enviar' : 'Próximo'}
              </Button>
            }
          </Box>
        </Fragment>

      </Box>
    </Card >
  )
}
