/* eslint-disable react-hooks/exhaustive-deps */
import { useApi } from '@hooks/api'
import { useAuth } from '@hooks/auth'
import { NewProjectPageContext } from './context'
import { ProjectApi } from '@services/api/project_api'
import { useCallback, useMemo, useState } from 'react'
import { NewProjectPageControllerProps } from './types'

export const NewProjectPageController = (
  props: NewProjectPageControllerProps
): JSX.Element => {
  const api = useApi()
  const { user } = useAuth()
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>('')

  const [summary, setSummary] = useState<string>('')
  const [description, setDescription] = useState<string>('')

  const [blocked, setBlocked] = useState<boolean>(true)

  const steps = ['Escopo do projeto', 'Requisitos', 'Submissão']

  const handleSubmitProject = useCallback(() => {
    setLoading(true)

    api
      .instanceOf<ProjectApi>(ProjectApi)
      .submit({ summary, description })
      .then()
      .catch((error: any) => {
        setError(error?.response?.data?.message || 'Erro ao processar sua requisição')
      })
      .finally(() => setLoading(false))
  }, [summary])

  const state = useMemo(
    () => ({
      user,
      steps,
      error,
      loading,
      blocked,
      summary,
      description,
      setSummary,
      setDescription,
      setBlocked,
      handleSubmitProject,
    }),
    [user, steps, error, loading, blocked, summary, setBlocked, setSummary, handleSubmitProject]
  )

  return (
    <NewProjectPageContext.Provider value={state}>
      {props.children}
    </NewProjectPageContext.Provider>
  )
}
