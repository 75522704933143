import { Box, Typography } from '@mui/material'
import { useContextSelector } from 'use-context-selector'
import { NewProjectPageContext } from '@pages/new-project/context'

export const Submit = (): JSX.Element => {
  const summary = useContextSelector(NewProjectPageContext, (s) => s.summary)

  const description = useContextSelector(
    NewProjectPageContext,
    (s) => s.description
  )

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Typography variant="h3">Resumo</Typography>
        <Typography>{summary}</Typography>
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Typography variant="h3">Descrição</Typography>
        <Typography>{description}</Typography>
      </Box>
    </Box>
  )
}
