import * as React from 'react';
import Box from '@mui/material/Box';
import { Card } from '@mui/material';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import programmer from '@assets/images/programmer.jpeg'
import MobileStepper from '@mui/material/MobileStepper';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';


const steps = [
  {
    label: 'Envie o seu projeto',
    description: `Descreva com a ajuda da nossa IA os requisitos que você deseja em seu projeto`,
  },
  {
    label: 'Análise Think',
    description:
      'Após submeter o seu projeto, nosso time irá quebralo em pequenas entregas para que seja possível avaliar o custo e profissionais necessários.',
  },
  {
    label: 'Aceite da proposta',
    description: `Nosso time irá enviar o custo total e tempo estimado para execução do projeto.`,
  },
  {
    label: 'Desenvolvimento',
    description: `Caso você aceite as tarefas serão disponibilizadas em nosso Marketplace para os Thinkers`,
  },
  {
    label: 'Entrega',
    description: `A cada semana você recebe incrementos do projeto para ser avaliado e a cada avaliação, paga pelo que foi aprovado.`,
  },
];

export function HomePage() {
  const theme = useTheme();
  const navigate = useNavigate()
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = steps.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Card sx={{ display: 'flex', gap: 2, justifyContent: 'space-between' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', flexGrow: 1, padding: 4 }}>
        <div>
          <Paper
            square
            elevation={0}
            sx={{
              display: 'flex',
              alignItems: 'center',
              height: 50,
              pl: 2,
              bgcolor: 'background.default',
            }}
          >
            <Typography variant='h4'>{steps[activeStep].label}</Typography>
          </Paper>
          <Box sx={{ height: 255, maxWidth: 400, width: '100%', p: 2 }}>
            {steps[activeStep].description}
          </Box>
          <MobileStepper
            variant="text"
            steps={maxSteps}
            position="static"
            activeStep={activeStep}
            nextButton={
              <Button
                size="small"
                onClick={handleNext}
                disabled={activeStep === maxSteps - 1}
              >
                Next
                {theme.direction === 'rtl' ? (
                  <KeyboardArrowLeft />
                ) : (
                  <KeyboardArrowRight />
                )}
              </Button>
            }
            backButton={
              <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                {theme.direction === 'rtl' ? (
                  <KeyboardArrowRight />
                ) : (
                  <KeyboardArrowLeft />
                )}
                Back
              </Button>
            }
          />
        </div>

        <Button variant='contained' onClick={() => navigate('/projects/new')}>Quero começar</Button>
      </Box>
      <Box sx={{ width: '50%' }}>
        <img src={programmer} alt="programmer" width='100%' height='100%' />
      </Box>
    </Card>
  );
}