/* eslint-disable react-hooks/exhaustive-deps */
import { useApi } from '@hooks/api'
import { TasksPageContext } from './context'
import { TaskApi } from '@services/api/task_api'
import { TasksPageControllerProps } from './types'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Task } from '@type/task'

export const TasksPageController = (
  props: TasksPageControllerProps
): JSX.Element => {
  const api = useApi()
  const [tasks, setTasks] = useState<Task[]>([])
  const [loading, setLoading] = useState<boolean>(true)

  const handleLoadTasks = useCallback(() => {
    setLoading(true)

    api
      .instanceOf<TaskApi>(TaskApi)
      .list()
      .then((Tasks: any) => {
        setTasks(Tasks)
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  useEffect(() => {
    handleLoadTasks()
  }, [])

  const state = useMemo(
    () => ({
      tasks,
      loading,
    }),
    [tasks, loading]
  )

  return (
    <TasksPageContext.Provider value={state}>
      {props.children}
    </TasksPageContext.Provider>
  )
}
