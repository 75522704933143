import Kanban from '@components/kanban'
import { ProjectDetailsPageContext } from './context'
import { useContextSelector } from 'use-context-selector'

import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  LinearProgress,
  Link,
  Typography,
} from '@mui/material'
import moment from 'moment'

export const ProjectDetailsPageView = (): JSX.Element => {
  const id = useContextSelector(ProjectDetailsPageContext, (s) => s.id)
  const tasks = useContextSelector(ProjectDetailsPageContext, (s) => s.tasks)

  const loading = useContextSelector(
    ProjectDetailsPageContext,
    (s) => s.loading
  )

  if (!!loading) {
    return <LinearProgress />
  }

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        padding: 6
      }}
    >
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="/">
          Projetos
        </Link>
        <Link underline="hover" color="inherit" href={`/${id}/details`}>
          {id}
        </Link>
        <Typography color="text.primary">Detalhes</Typography>
      </Breadcrumbs>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Typography>
          <b>{moment().diff(moment().isoWeekday(4), 'days').toString()} dias</b> restantes até a próxima entrega
        </Typography>
        <Button variant="contained" onClick={() => window.open('https://api.whatsapp.com/send?phone=5511993999854&text=Ol%C3%A1,%20gostaria%20de%20saber%20mais%20sobre%20as%20solu%C3%A7%C3%B5es%20da%20Think%20Forward')}>Falar com o time</Button>
      </Box>

      <Kanban tasks={tasks} />
    </Card>
  )
}
