import { PortifolioPageContext } from './context'
import { Box, LinearProgress } from '@mui/material'
import { useContextSelector } from 'use-context-selector'

export const PortifolioPageView = (): JSX.Element => {
  const loading = useContextSelector(PortifolioPageContext, (s) => s.loading)

  if (!!loading) {
    return <LinearProgress />
  }

  return <Box sx={{ display: 'flex', flexDirection: 'column' }}></Box>
}
