import { ProjectDetailsPageView } from './view'
import { ProjectDetailsPageProps } from './types'
import { ProjectDetailsPageController } from './controller'

export const ProjectDetailsPage = (props: ProjectDetailsPageProps): JSX.Element => {
  return (
    <ProjectDetailsPageController {...props}>
      <ProjectDetailsPageView />
    </ProjectDetailsPageController>
  )
}
