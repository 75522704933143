import React from 'react'
import Item from './item'
import { Task } from '@type/task'
import { useEffect, useState } from 'react'
import { Droppable, DroppableProps } from 'react-beautiful-dnd'
import { Box, Typography } from '@mui/material'

interface ColumnProps {
  col: {
    id: string
    name: string
    list: Task[]
  }
}

export const StrictModeDroppable = ({ children, ...props }: DroppableProps) => {
  const [enabled, setEnabled] = useState(false)
  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true))
    return () => {
      cancelAnimationFrame(animation)
      setEnabled(false)
    }
  }, [])
  if (!enabled) {
    return null
  }
  return <Droppable {...props}>{children}</Droppable>
}

const Column: React.FC<ColumnProps> = ({ col: { list, id, name } }) => {
  return (
    <StrictModeDroppable droppableId={id}>
      {(provided) => (
        <Box
          key={id}
          style={{
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: '#f7f8f9',
            alignItems: 'center',
            borderRadius: 10,
            padding: 15,
            gap: 20,
          }}
        >
          <Typography variant="h4">
            {name} - ({list.length})
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              minHeight: '120px',
              gap: 2,
            }}
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {list.map((element, index) => (
              <Item key={index} element={element} index={index} />
            ))}
            {provided.placeholder}
          </Box>
        </Box>
      )}
    </StrictModeDroppable>
  )
}

export default Column
