import logoAsset from '@assets/images/logo.png'
import { Box, Typography } from '@mui/material'

export const HeaderLogo = (): JSX.Element => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
      <img src={logoAsset} alt="ThinkCrm" height={42} />
      <Typography variant='h3'>Think Hub</Typography>
    </Box>
  )
}
