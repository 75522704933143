/* eslint-disable react-hooks/exhaustive-deps */
import { useApi } from '@hooks/api'
import { Project } from '@type/project'
import { useNavigate } from 'react-router-dom'
import { ProjectsPageContext } from './context'
import { ProjectsPageControllerProps } from './types'
import { ProjectApi } from '@services/api/project_api'
import { useCallback, useEffect, useMemo, useState } from 'react'

export const ProjectsPageController = (
  props: ProjectsPageControllerProps
): JSX.Element => {
  const api = useApi()
  const navigate = useNavigate()
  const [loading, setLoading] = useState<boolean>(true)
  const [projects, setProjects] = useState<Project[]>([])

  const handleAcceptProject = useCallback((id: string) => {
    setLoading(true)

    api
      .instanceOf<ProjectApi>(ProjectApi)
      .accept(id)
      .then(() => {
        const updatedProjects = projects.map((project) => {
          if (project.id === id) {
            project.status = 'accepted'
          }
          return project
        })

        setProjects(updatedProjects)
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  const handleRejectProject = useCallback((id: string) => {
    setLoading(true)

    api
      .instanceOf<ProjectApi>(ProjectApi)
      .reject(id)
      .then(() => {
        const updatedProjects = projects.map((project) => {
          if (project.id === id) {
            project.status = 'rejected'
          }
          return project
        })

        setProjects(updatedProjects)
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  const handleLoadProjects = useCallback(() => {
    setLoading(true)

    api
      .instanceOf<ProjectApi>(ProjectApi)
      .list()
      .then((projects: Project[]) => {
        setProjects(projects)
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  useEffect(() => {
    handleLoadProjects()
  }, [])

  const goToProjectDetail = (id: string) => {
    navigate(`/projects/${id}/details`)
  }

  const state = useMemo(
    () => ({
      loading,
      projects,
      goToProjectDetail,
      handleAcceptProject,
      handleRejectProject,
    }),
    [
      loading,
      projects,
      goToProjectDetail,
      handleAcceptProject,
      handleRejectProject,
    ]
  )

  return (
    <ProjectsPageContext.Provider value={state}>
      {props.children}
    </ProjectsPageContext.Provider>
  )
}
