/* eslint-disable react-hooks/exhaustive-deps */
import { useApi } from '@hooks/api'
import { useAuth } from '@hooks/auth'
import { SessionPageContext } from './context'
import { AuthApi } from '@services/api/auth_api'
import { UserEntity } from '@entities/UserEntity'
import { SessionPageControllerProps } from './types'
import { useCallback, useEffect, useMemo, useState } from 'react'

export const SessionPageController = (
  props: SessionPageControllerProps
): JSX.Element => {
  const api = useApi()
  const auth = useAuth()

  const [loading, setLoading] = useState<boolean>(true)

  const handleDefaultProfile = (user: UserEntity) => {
    if (auth.user?.context) {
      return auth.setContext()
    }
    auth.setUser(user)
  }

  const handleLoadSession = useCallback(() => {
    setLoading(true)
    api
      .instanceOf<AuthApi>(AuthApi)
      .session()
      .then(handleDefaultProfile)
      .catch(() => auth.disconnect())
      .finally(() => setLoading(false))
  }, [])

  useEffect(() => {
    handleLoadSession()
  }, [])

  const state = useMemo(
    () => ({
      loading,
    }),
    [loading]
  )

  return (
    <SessionPageContext.Provider value={state}>
      {props.children}
    </SessionPageContext.Provider>
  )
}
