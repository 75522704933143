import '@nlux/themes/nova.css'
import './style.css'
import { useEffect, useState } from 'react'
import { useApi } from '@hooks/api'
import { AiChat } from '@nlux/react'
import { IAApi } from '@services/api/ia_api'
import { user, botPictureUrl } from './personas'
import { useContextSelector } from 'use-context-selector'
import { NewProjectPageContext } from '@pages/new-project/context'
import { Typography } from '@mui/material'

export const Bot = (): JSX.Element => {
  const api = useApi()
  const [count, setCount] = useState(1)

  useEffect(() => {
    sessionStorage.removeItem('threadId')
  }, [])
  
  const setBlocked = useContextSelector(
    NewProjectPageContext,
    (s) => s.setBlocked
  )

  const setDescription = useContextSelector(
    NewProjectPageContext,
    (s) => s.setDescription
  )

  const handleMessageSent = () => {
    setCount((c) => c - 1)
  }

  const handleMessageReceived = (message: string) => {
    setDescription(message)

    if (count <= 0) {
      setBlocked(false)
    }
  }

  return (
    <>
      <AiChat
        className="custom-ai-chat-comp"
        adapter={api
          .instanceOf<IAApi>(IAApi)
        }
        events={{
          messageSent: handleMessageSent,
          messageReceived: handleMessageReceived,
        }}
        personaOptions={{
          bot: {
            name: 'Think Product Manager',
            tagline:
              'Sou o seu gestor de projetos e vou te ajudar a melhor descrever o que deseja construir',
            picture: botPictureUrl,
          },
          user,
        }}
        promptBoxOptions={{
          placeholder: 'O que você deseja construir?',
        }}
        layoutOptions={{
          height: 'calc(50vh)',
          width: '100%',
        }}
      />

      <Typography color="$color-text-primary" variant='subtitle2' sx={{ mt: 2 }}>Após estar satisfeito com o escopo construído, clique em prosseguir.</Typography>
    </>
  )
}
