import * as React from 'react';
import HomeIcon from '@mui/icons-material/Home';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import RocketIcon from '@mui/icons-material/Rocket';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PostAddtIcon from '@mui/icons-material/PostAdd';
import ListItemButton from '@mui/material/ListItemButton';
import { useAuth } from '@hooks/auth';

const inactive = {}
const path = window.location.pathname
const active = { backgroundColor: '#E63372', color: 'white', borderRadius: 1.5 }
const activeIcon = { color: 'white' }

export const organizationListItems = (
    < React.Fragment >
        <ListItemButton href='/' sx={path === '/' ? active : inactive}>
            <ListItemIcon>
                <HomeIcon sx={path === '/' ? activeIcon : inactive} />
            </ListItemIcon>
            <ListItemText primary="Home" />
        </ListItemButton>
        <ListItemButton href='/projects/new' sx={path === '/projects/new' ? active : inactive}>
            <ListItemIcon>
                <PostAddtIcon sx={path === '/projects/new' ? activeIcon : inactive} />
            </ListItemIcon>
            <ListItemText primary="Novo projeto" />
        </ListItemButton>
        <ListItemButton href='/projects' sx={path === '/projects' ? active : inactive}>
            <ListItemIcon>
                <RocketIcon sx={path === '/projects' ? activeIcon : inactive} />
            </ListItemIcon>
            <ListItemText primary="Meus projetos" />
        </ListItemButton>
    </React.Fragment >
)


export const professionalListItems = (
    < React.Fragment >
        <ListItemButton href='/tasks' sx={path === '/tasks' ? active : inactive}>
            <ListItemIcon>
                <HomeIcon sx={path === '/tasks' ? activeIcon : inactive} />
            </ListItemIcon>
            <ListItemText primary="Tarefas" />
        </ListItemButton>
        <ListItemButton href='/sprint' sx={path === '/sprint' ? active : inactive}>
            <ListItemIcon>
                <RocketIcon sx={path === '/sprint' ? activeIcon : inactive} />
            </ListItemIcon>
            <ListItemText primary="Sprint" />
        </ListItemButton>
    </React.Fragment >
)


export const SecondaryListItems = () => {
    const { account_host } = useAuth()
    return (
        <React.Fragment>
            <ListItemButton href={account_host}>
                <ListItemIcon>
                    <AccountBoxIcon />
                </ListItemIcon>
                <ListItemText primary="Minha conta" />
            </ListItemButton>
        </React.Fragment>
    );
}