import moment from 'moment'
import Kanban from '@components/kanban'
import { SprintPageContext } from './context'
import { useContextSelector } from 'use-context-selector'
import { Box, Button, Card, LinearProgress, Typography } from '@mui/material'

export const SprintPageView = (): JSX.Element => {
  const loading = useContextSelector(SprintPageContext, (s) => s.loading)

  if (!!loading) {
    return <LinearProgress />
  }

  return (
    <Card sx={{ display: 'flex', flexDirection: 'column', gap: 2, padding: 4 }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Typography>
          <b>{moment().diff(moment().isoWeekday(4), 'days').toString()} dias</b> restantes para o final da Sprint
        </Typography>
        <Button variant="contained" onClick={() => window.open('https://api.whatsapp.com/send?phone=5511993999854&text=Ol%C3%A1,%20gostaria%20de%20saber%20mais%20sobre%20as%20solu%C3%A7%C3%B5es%20da%20Think%20Forward')}>Falar com o time</Button>
      </Box>

      <Typography>
        A qualquer momento, caso encontre dificuldades ou tenha algum problema,
        fale com o nosso time.
      </Typography>
      <Kanban tasks={[]} />
    </Card>
  )
}
