import Column from './column'
import { Task } from '@type/task'
import { Box } from '@mui/material'
import { useEffect, useState } from 'react'
import { DragDropContext, DropResult } from 'react-beautiful-dnd'

const initialColumns = {
  sprint: {
    id: 'sprint',
    name: 'Próxima Sprint',
    list: [] as Task[],
  },
  avialable: {
    id: 'avialable',
    name: 'Disponíveis',
    list: [] as Task[],
  },
  backlog: {
    id: 'backlog',
    name: 'Backlog',
    list: [] as Task[],
  },
} as Record<string, any>

export interface BacklogProps {
  tasks: Task[]
}

export default function Backlog({ tasks }: BacklogProps) {
  const [columns, setColumns] = useState(initialColumns)

  useEffect(() => {
    const newColumns = Object.assign(initialColumns)
    newColumns.avialable = Object.assign(newColumns.avialable, { list: tasks })
    setColumns(newColumns)
  }, [tasks])

  const onDragEnd = ({ source, destination }: DropResult) => {
    // Make sure we have a valid destination
    if (destination === undefined || destination === null) return null

    // If the source and destination columns are the same
    // AND if the index is the same, the item isn't moving
    if (
      source.droppableId === destination.droppableId &&
      destination.index === source.index
    )
      return null

    // Set start and end variables
    const start = columns[source.droppableId]
    const end = columns[destination.droppableId]

    // If start is the same as end, we're in the same column
    // If start is the same as end, we're in the same column
    if (start === end) {
      /* ... */
    } else {
      // If start is different from end, we need to update multiple columns
      // Filter the start list like before
      const newStartList = start.list.filter(
        (_: any, idx: number) => idx !== source.index
      )

      // Create a new start column
      const newStartCol = {
        id: start.id,
        name: start.name,
        list: newStartList,
      }

      // Make a new end list array
      const newEndList = end.list

      // Insert the item into the end list
      newEndList.splice(destination.index, 0, start.list[source.index])

      // Create a new end column
      const newEndCol = {
        id: end.id,
        name: end.name,
        list: newEndList,
      }

      // Update the state
      setColumns((state) => ({
        ...state,
        [newStartCol.id]: newStartCol,
        [newEndCol.id]: newEndCol,
      }))
      return null
    }

    return null
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        {Object.values(columns).map((col) => (
          <Column col={col} key={col.id} />
        ))}
      </Box>
    </DragDropContext>
  )
}
