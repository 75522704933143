import { HomePage } from '@pages/home'
import { TasksPage } from '@pages/tasks'
import { SprintPage } from '@pages/sprint'
import { LoadingPage } from '@pages/loading'
import { SessionPage } from '@pages/session'
import { ProjectsPage } from '@pages/projects'
import { PortifolioPage } from '@pages/portfolio'
import { NewProjectPage } from '@pages/new-project'
import { WebsocketProvider } from '@hooks/websocket'
import { OAuthCallbackPage } from '@pages/oauth_callback'
import { ProjectDetailsPage } from '@pages/project-details'
import { Routes, Route, Outlet, Navigate } from 'react-router-dom'

function WebsocketRoutes() {
  return (
    <WebsocketProvider>
      <Outlet />
    </WebsocketProvider>
  )
}

function Router() {
  return (
    <Routes>
      <Route element={<SessionPage />}>
        <Route element={<WebsocketRoutes />}>
          <Route
            path="oauth_callback/:resource/:provider"
            element={<OAuthCallbackPage />}
          />

          <Route path="loading" element={<LoadingPage />} />

          <Route path="/account-type" />

          <Route path="/" >
            <Route path="" element={<HomePage />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Route>

          <Route path="projects" >
            <Route path="" element={<ProjectsPage />} />
            <Route path="new" element={<NewProjectPage />} />
            <Route path=":id/details" element={<ProjectDetailsPage />} />
            <Route path="*" element={<Navigate to="/projects" replace />} />
          </Route>

          <Route path="tasks" >
            <Route path="" element={<TasksPage />} />
            <Route path="*" element={<Navigate to="/tasks" replace />} />
          </Route>

          <Route path="sprint" >
            <Route path="" element={<SprintPage />} />
            <Route path="*" element={<Navigate to="/sprint" replace />} />
          </Route>

          <Route path="portfolio" >
            <Route path="" element={<PortifolioPage />} />
            <Route path="*" element={<Navigate to="/portfolio" replace />} />
          </Route>

          <Route path="*" element={<Navigate to="/loading" replace />} />
        </Route>
      </Route>
      <Route path="*" element={<div>404</div>} />
    </Routes>
  )
}

export default Router
