/* eslint-disable react-hooks/exhaustive-deps */
import { Task } from '@type/task'
import { useApi } from '@hooks/api'
import { useParams } from 'react-router-dom'
import { ProjectDetailsPageContext } from './context'
import { ProjectApi } from '@services/api/project_api'
import { ProjectDetailsPageControllerProps } from './types'
import { useCallback, useEffect, useMemo, useState } from 'react'

export const ProjectDetailsPageController = (
  props: ProjectDetailsPageControllerProps
): JSX.Element => {
  const api = useApi()
  const { id } = useParams()
  const [tasks, seTasks] = useState<Task[]>([])
  const [loading, setLoading] = useState<boolean>(true)

  const handleLoadProjectDetails = useCallback(() => {
    setLoading(true)

    api
      .instanceOf<ProjectApi>(ProjectApi)
      .tasks(id || '')
      .then((tasks: any) => {
        seTasks(tasks)
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  useEffect(() => {
    handleLoadProjectDetails()
  }, [])

  const state = useMemo(
    () => ({
      id,
      tasks,
      loading,
    }),
    [id, tasks, loading]
  )

  return (
    <ProjectDetailsPageContext.Provider value={state}>
      {props.children}
    </ProjectDetailsPageContext.Provider>
  )
}
