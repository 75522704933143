import { ApiAction } from '@hooks/api/action'
import { Adapter, StreamingAdapterObserver } from '@nlux/react'

function timeout(delay: number) {
  return new Promise(res => setTimeout(res, delay));
}

export class IAApi extends ApiAction implements Adapter {
  async getMessage(threadId: string, runId: string): Promise<string> {
    const response = await this.http.get(`/ia/pm/${threadId}/${runId}`)

    if (response.data.status !== "completed") {
      await timeout(2000)
      return this.getMessage(threadId, runId)
    }

    return response.data.data[0].text
  }


  async streamText(prompt: string, observer: StreamingAdapterObserver) {
    const threadId = sessionStorage.getItem('threadId') || ''
    console.log(threadId)
    const response = await this.http.get(`/ia/pm?q=${prompt}&thread=${threadId}`)

    if (response.status !== 200) {
      observer.error(new Error('Failed to connect to the server'))
      return
    }

    if (!response.data) {
      return
    }

    sessionStorage.setItem('threadId', response.data.thread)

    if (response.data.status !== "completed") {
      await timeout(1000)
      const message = await this.getMessage(response.data.thread, response.data.runId)
      observer.next(message)
      observer.complete()
    } else {
      observer.next(response.data.data[0].text)
      observer.complete()
    }
  }
}
