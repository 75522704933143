import { Task } from '@type/task'
import { Project } from '@type/project'
import { ApiAction } from '../../hooks/api/action'

export class ProjectApi extends ApiAction {
  async submit(
    data: Partial<Pick<Project, 'description' | 'summary'>>
  ): Promise<Project> {
    const response = await this.http.post<any>(`/projects`, data)
    return response.data
  }

  async list(): Promise<Project[]> {
    const response = await this.http.get<any>(`/projects`)
    return response.data
  }

  async tasks(id: string): Promise<Task[]> {
    const response = await this.http.get<any>(`/projects/${id}/tasks`)
    return response.data
  }

  async accept(id: string): Promise<void> {
    const response = await this.http.put<any>(`/projects/${id}/accept`)
    return response.data
  }

  async reject(id: string): Promise<void> {
    const response = await this.http.put<any>(`/projects/${id}/reject`)
    return response.data
  }
}
