import { ProjectsPageContext } from '../context'
import { Project as ProjectEntity } from '@type/project'
import { useContextSelector } from 'use-context-selector'
import { ProjectStatusEnum } from '@enums/project_status_enum'

import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from '@mui/material'

export default function Project({ project }: { project: ProjectEntity }) {
  const goToProjectDetail = useContextSelector(
    ProjectsPageContext,
    (s) => s.goToProjectDetail
  )

  const handleAcceptProject = useContextSelector(
    ProjectsPageContext,
    (s) => s.handleAcceptProject
  )

  const handleRejectProject = useContextSelector(
    ProjectsPageContext,
    (s) => s.handleRejectProject
  )

  return (
    <Card sx={{ width: 950, borderRadius: 4, padding: 2 }}>
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Typography sx={{ fontSize: 14 }} color="primary">
          {ProjectStatusEnum[project.status] || 'Status não definido'}
        </Typography>

        <Typography
          variant="h1"
          sx={{ fontSize: 14 }}
          color="text.secondary"
          gutterBottom
        >
          {`R$ ${project.value || '0,00'}`}
        </Typography>
      </CardContent>

      <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Typography variant="h5" component="div">
          {project.summary}
        </Typography>
        <Typography variant="body2">{project.description}</Typography>
      </CardContent>

      <CardActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <Button
            size="small"
            disabled={project.status !== 'waiting_client'}
            onClick={() => handleRejectProject(project.id)}
          >
            Recursar proposta
          </Button>
          <Button
            size="small"
            variant="outlined"
            disabled={project.status !== 'waiting_client'}
            onClick={() => handleAcceptProject(project.id)}
          >
            Aceitar proposta
          </Button>
        </div>

        <Button
          size="small"
          variant="contained"
          onClick={() => goToProjectDetail(project.id)}
          sx={{ justifySelf: 'flex-end' }}
        >
          Detalhes
        </Button>
      </CardActions>
    </Card>
  )
}
