/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
// import { useAuth } from '@hooks/auth'
import { LinearProgress } from '@mui/material'
import { UserEntity } from '@entities/UserEntity'
// import { useContextSelector } from 'use-context-selector'
import { useNavigate } from 'react-router-dom'
// import { SessionPageContext } from '@pages/session/context'

export const LoadingPage = () => {
  // const auth = useAuth()
  const navigate = useNavigate()
  // const { pathname } = useLocation()

  // const selectedProfile = useContextSelector(
  //   SessionPageContext,
  //   (s) => s.selectedProfile
  // )

  const handleRedirectUser = (user: UserEntity) => {
    navigate('/projects', {
      replace: true,
    })
  }

  useEffect(() => {
    handleRedirectUser({} as UserEntity)
    // if (auth?.user) {
    //   handleRedirectUser(auth.user)
    // }
  }, [])

  return <LinearProgress />
}
