import { ProjectsPageView } from './view'
import { ProjectsPageProps } from './types'
import { ProjectsPageController } from './controller'

export const ProjectsPage = (props: ProjectsPageProps): JSX.Element => {
  return (
    <ProjectsPageController {...props}>
      <ProjectsPageView />
    </ProjectsPageController>
  )
}
