import { useContextSelector } from 'use-context-selector'
import { NewProjectPageContext } from '@pages/new-project/context'
import { FormControl, FormGroup, Input, TextField, Typography } from '@mui/material'

export const Form = (): JSX.Element => {
  const summary = useContextSelector(NewProjectPageContext, (s) => s.summary)

  const setSummary = useContextSelector(
    NewProjectPageContext,
    (s) => s.setSummary
  )

  const description = useContextSelector(
    NewProjectPageContext,
    (s) => s.description
  )

  const setDescription = useContextSelector(
    NewProjectPageContext,
    (s) => s.setDescription
  )

  return (
    <FormGroup sx={{ gap: 10 }}>
      <FormControl sx={{ gap: 3 }}>
        <Typography variant='h4'>Titulo para seu projeto</Typography>
        <Input
          id="summary"
          value={summary}
          placeholder="Ex: Site institucional da minha empres"
          onChange={(e) => setSummary(e.target.value)}
        />
      </FormControl>

      <FormControl>
        <TextField
          id="description"
          label="Descrição do projeto"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          multiline
          rows={16}
        />
      </FormControl>
    </FormGroup>
  )
}
