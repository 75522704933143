import { HeaderLogo } from './logo'
import { useAuth } from '@hooks/auth'
import { Card, Divider, List } from '@mui/material'
import { DisconnectButton } from './disconnect-button'
import { professionalListItems, organizationListItems, SecondaryListItems } from './items'


export const MenuAuthenticated = (): JSX.Element => {
  const { user } = useAuth()
  return (
    <Card sx={{ display: 'flex', flexDirection: 'column', padding: 3, borderRadius: 4, minWidth: 280, }}>
      <HeaderLogo />
      <Divider sx={{ my: 1, mt: 2 }} />
      <List component="nav">
        {user?.context === "PROFESSIONAL" ? professionalListItems : organizationListItems}
        <Divider sx={{ my: 1 }} />
        <SecondaryListItems />
      </List>
      <DisconnectButton />
    </Card>
  )
}
