import Project from './components/project'
import { ProjectsPageContext } from './context'
import { Box, LinearProgress } from '@mui/material'
import { useContextSelector } from 'use-context-selector'

export const ProjectsPageView = (): JSX.Element => {
  const loading = useContextSelector(ProjectsPageContext, (s) => s.loading)

  const projects = useContextSelector(ProjectsPageContext, (s) => s.projects)

  if (!!loading) {
    return <LinearProgress />
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
      {projects.map((project) => (
        <Project key={project.id} project={project} />
      ))}
    </Box>
  )
}
